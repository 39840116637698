export function ScrollToUp() {

	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});

}

export const setGTM = () => {

	const GTM = process.env.NEXT_PUBLIC_GTM_ID;

	return {
		__html: `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${GTM}');
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${GTM}');
			`,
	};

};

export const setGTM2 = () => {

	const GTM2 = process.env.NEXT_PUBLIC_GTM_ID2;

	return {
		__html: `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${GTM2}');
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${GTM2}');
			`,
	};

};

export const comSocre = () => ({
	__html: `
			var _comscore = _comscore || [];
			_comscore.push({ c1: "2", c2: "8443234" });
			(function() {
			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
			s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
			el.parentNode.insertBefore(s, el);
			})();
		`,
});

export const setGoogleAnalytics = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;
	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;

	return {
		__html: `
		var _gaq = _gaq || [];
		_gaq.push(['_setAccount', '${GaHost}']);
		_gaq.push(['_trackPageview']);

		_gaq.push(['fdn._setAccount', '${GaGeneral}']);
		_gaq.push(['fdn._setDomainName', 'femaledaily.com']);
		_gaq.push(['fdn._setAllowLinker', true]);
		_gaq.push(['fdn._trackPageview']);

		(function() {
			var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
			ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
		})(); 
		`,
	};

};

export const setGoogleAnalyticsNoscript = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaGeneral}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setGoogleAnalyticsNoscriptGaHost = () => {

	const GaHost = process.env.NEXT_PUBLIC_GA_HOST_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GaHost}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setVideoWidget = () => ({
	__html: `
	var mobile = Boolean(window.navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

	var isActive = false

	function handleOpen(){

		if(!mobile){
			if(!isActive){
				document.getElementsByClassName("floating-button")[0].style.bottom = "10vh"
				document.getElementsByClassName("floating-button")[0].style.right = "calc(6vw + 478px)"
			} else {
				document.getElementsByClassName("floating-button")[0].style.bottom = "calc(10vh + 100px)"
				document.getElementsByClassName("floating-button")[0].style.right = "4vw"
			}
		} 
		
		if(mobile){
			if(!isActive){
				document.getElementsByClassName("floating-button")[0].style.bottom = "calc((100vw * 9/16) + 64px + 24px)"
			} else {
				document.getElementsByClassName("floating-button")[0].style.bottom = "calc(10vh + 24px)"
			}
		}

		isActive = !isActive
	}

	function handleClose(){
		if(!mobile){
			document.getElementsByClassName("floating-button")[0].style.bottom = "10vh"
			document.getElementsByClassName("floating-button")[0].style.right = "4vw"
		} else {
			document.getElementsByClassName("floating-button")[0].style.bottom = "10vh"
		}
	}

	Video.config({
		isMobile: mobile,
		unique_code: "fd2kale",
		selector: "#vidwidget",
		domain2: "${process.env.NEXT_PUBLIC_ROOT_DOMAIN}",
		isAds: false,
		position: "fixed",
		elementId: "videoWidgetStreaming",
		handleOpen: handleOpen,
		closeWidget: handleClose,
	});

	Video.widgets.render();
		`,
});

export const uniqueId = () => {

	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;

};
